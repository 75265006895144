import React, { useState } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"

const SecureYourHealth = ({
data
}) => {

let metaTag = []
let pageInfo = {}
let image = null
if (data?.allNodeTemplatePages?.edges) {
pageInfo = data.allNodeTemplatePages.edges[0]?.node
metaTag = []
image = pageInfo.relationships.components[1]?.relationships.field_components[0]?.relationships?.image?.uri?.url
}
return (
<>
<Layout>
    <Meta
    files={{
    js: [],
    css: [
    "/assets/css/about-through-the-ages.css",
    "/assets/css/accessibility.css"
    ],
    }}
    tags={metaTag}
    />
    <main className="innerpage">
        <section className="section_bg pt-first-section pb-section about-ages">
            <div className="container">
                <div className="section-head">
                    <h1>{pageInfo?.title || ""}</h1>
                    {
                    (pageInfo?.relationships?.components && pageInfo.relationships.components[0]) &&
                    <div dangerouslySetInnerHTML={{ __html: pageInfo.relationships.components[0]?.htmlText.processed}}>
                </div>
                }
            </div>
            </div>
            {
            (pageInfo?.relationships?.components && pageInfo.relationships.components[1]) &&
            <div className="container">
                <div className="tab-content mt-3">
                    <div className="tab-pane active" id="secureyourhealth" role="tabpanel" aria-labelledby="secureyourhealth">
                        <div className="card">
                            <div className="row">
                                <div className="col-md-6">
                                    <img src={image} alt="Secure your health"/>
                                </div>
                                <div className="col-md-6 para_text">
                                    <div dangerouslySetInnerHTML={{ __html: pageInfo.relationships.components[1]?.relationships.field_components[0]?.text?.processed}}>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            }
        </section>
    </main>
</Layout>
</>
)
}
export default SecureYourHealth
export const query = graphql`
query SecureYourHealth {
allNodeTemplatePages(filter: {path: {alias: {regex: "/secure-your-health$/"}}}) {
edges {
node {
id
title
path {
alias
}
metatag {
    attributes {
        content
        href
        name
        property
        rel
    }
}
relationships {
components: field_component_type {
__typename
...ParagraphHTMLText
... on paragraph__tab_components {
relationships {
field_components {
...ParagraphImageText
}
}
}
}
}
}
}
}
}
`